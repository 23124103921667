import React from "react";
import Term from './components/Term'
function App() {
  return (
    <div className="container">
      <Term/>
    </div>
  );
}

export default App;
